<template>
  <v-col style="margin-left:60px !important" cols="8" class="mx-8">
    <h3 class="passDesc" justify="center">
      New passwords must meet the following requirements:
    </h3>
    <ul class="mb-6">
      <li class="passReq">At least 8 characters</li>
      <li class="passReq">At least 1 lowercase letter (a-z)</li>
      <li class="passReq">At least 1 uppercase letter (A-Z)</li>
      <li class="passReq">At least 1 number (0-9)</li>
      <li class="passReq">Does not contain any part of your username</li>
    </ul>

    <v-form ref="form" v-model="valid">
      <v-text-field
        v-model="currentPassword"
        label="Current Password"
        :rules="currentPasswordRules"
        required
        :type="showCurrentPassword ? 'text' : 'password'"
        :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showCurrentPassword = !showCurrentPassword"
      />
      <v-text-field
        v-model="newPassword"
        label="New Password"
        :rules="passwordRules"
        required
        :type="showNewPassword ? 'text' : 'password'"
        :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showNewPassword = !showNewPassword"
      />
      <v-text-field
        v-model="confirmNewPassword"
        label="Confirm New Password"
        :rules="confirmPasswordRules"
        required
        :type="showConfirmPassword ? 'text' : 'password'"
        :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showConfirmPassword = !showConfirmPassword"
      />
      <v-row class="ml-1 mt-4">
        <v-btn
          small
          outlined
          @click="UpdatePassword"
          :disabled="!valid || newPassword !== confirmNewPassword || !userInfo"
          :color="valid ? 'green' : null"
        >Change Password</v-btn>
      </v-row>
    </v-form>
  </v-col>
</template>


<script>
import axios from 'axios';
import rules from '../utils/field-validations';

export default {
  name: "change-password",
  props: {
    userInfo: Object,
  },
  data() {
    return {
      valid: true,
      currentPassword: undefined,
      newPassword: undefined,
      confirmNewPassword: undefined,
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      currentPasswordRules: [v => !!v || "Current Password is required"],
      passwordRules: [],
      confirmPasswordRules: [],
      loading: false,
    };
  },
  watch: {
    userInfo: "setRules"
  },
  created() {
    this.setRules();
  },
  methods: {
    setRules() {
      if (this.userInfo) {
        this.passwordRules = [
          ...rules.password,
          v => !!v && !this.userInfo.email
            .split('@')[0]
            .replace(/[-._]/g, '')
            .toLowerCase()
            .split('')
            .find(function (letter, index, splitname) {
              var username = splitname.join('');
              if (v.length >= 3) {
                for (let i = 0; i < v.length-3; i++) {
                  if (username.includes(v.toLowerCase().substring(i, i+3))) {
                    return true;
                  }
                }
              }
              return false;
            }) || "Passwords should not contain parts of the username.",
        ];
        this.confirmPasswordRules = [
          v => v === this.newPassword || "Passwords do not match"
        ];
      }
    },
    async UpdatePassword() {
      this.loading = true;
      const payload = {
        oldPassword: { value: this.currentPassword },
        newPassword: { value: this.newPassword }
      };
      try {
        await axios.post(
          `${process.env.VUE_APP_SSO_API_URL}/users/${this.userInfo.sub}/credentials/password?version=${process.env.VUE_APP_VERSION}`,
          JSON.stringify(payload),
          { headers: {
            Authorization: `Bearer ${this.$auth.getAccessToken()} ${process.env.VUE_APP_VERSION}`,
          }}
        )
        this.$emit("displayAlert", "Password updated successfully", false);
        this.$refs.form.reset();
        this.loading = false;

      } catch (err) {
        let errorMessage = 'Unable to process your request';
        if (err.response && err.response.data && err.response.data.message) {
          if (err.response.data.message.includes('403')) {
            errorMessage = 'Incorrect current password or this new password has been used recently.';
          } else {
            errorMessage = err.response.data.message;
          }
        }
        this.$emit("displayAlert", errorMessage, true);
        this.loading = false;
      }
    }
  }
};
</script>
