<template>
  <div>
    <v-container fluid color-white>
      <v-row>
        <v-container id="helpPage">
          <div id="top">
            <v-btn id="backToLogin"
              text
              color
              @click="goBack()"
            >
              <v-icon
                x-small
                dark
                class="back-arrow"
              >mdi-arrow-left</v-icon>
              Back
            </v-btn>
            <h1 class="header">How can we help?</h1>
          </div>

          <!-- HELP CARDS -->
          <div id="helpCardsContainer">
            <v-row
              class="iepadding"
              align="center"
              justify="center"
            >
              <v-col class="helpCard">
                <v-hover>
                  <v-card
                    slot-scope="{ hover }"
                    :class="`elevation-${hover ? 12 : 2}`"
                    @click="handleClickHelpLink('needHelp')"
                  >
                    <v-row class="helpCardContainer ma-2">
                      <v-col class="helpIcon" align="left" justify="center">
                        <v-icon color="#43A1DA" x-large>
                          mdi-account-question-outline
                        </v-icon>
                      </v-col>
                      <v-col class="helpTextContainer">
                        <v-card-title class="helpText">Need help?</v-card-title>
                        <v-card-subtitle class="helpSubtext">
                          Let us know how we can assist you.
                        </v-card-subtitle>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-hover>
              </v-col>
              <v-col class="helpCard">
                <v-hover>
                  <v-card
                    slot-scope="{ hover }"
                    :class="`elevation-${hover ? 12 : 2}`"
                    @click="handleClickHelpLink('access')"
                  >
                    <v-row class="helpCardContainer ma-2">
                      <v-col class="helpIcon" align="left" justify="center">
                        <v-icon color="#43A1DA" x-large>
                          mdi-lock-open-check-outline
                        </v-icon>
                      </v-col>
                      <v-col class="helpTextContainer">
                        <v-card-title class="helpText">Request Access</v-card-title>
                        <v-card-subtitle class="helpSubtext">
                          We can help you get access for an application.
                        </v-card-subtitle>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-hover>
              </v-col>
              <v-col class="helpCard">
                <v-hover>
                  <v-card
                    slot-scope="{ hover }"
                    :class="`elevation-${hover ? 12 : 2}`"
                    @click="handleClickHelpLink('report')"
                  >
                    <v-row class="helpCardContainer ma-2">
                      <v-col class="helpIcon" align="left" justify="center">
                        <v-icon color="#43A1DA" x-large>
                          mdi-message-alert-outline
                        </v-icon>
                      </v-col>
                      <v-col class="helpTextContainer">
                        <v-card-title class="helpText">Send Feedback</v-card-title>
                        <v-card-subtitle class="helpSubtext">
                          Let us know how we can improve your experience.
                        </v-card-subtitle>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </div>
          <!-- END help cards -->

          <!-- MAIN: WELCOME, FAQ, HOW-TOS -->
          <v-row>
            <v-col cols="8">
              <v-card flat id="main" class="mr-6">
                <v-row class="ma-2">
                  <v-col md="auto">
                    <v-row>
                      <v-col :class="`d-flex justify-start`" md="auto">
                        <h1 class="helpTitle">Welcome</h1>
                      </v-col>
                    </v-row>
                    <v-card
                      class="mx-auto pa-4 paragraphText"
                      max-width="800"
                      color="grey lighten-5"
                      flat
                    >
                      <p>
                        Welcome to the Moody’s Single Sign On platform. You can now
                        easily sign in to all your applications through a single login
                        and password.
                      </p>
                      <p>
                        Once you sign in, you will either be taken into the
                        application directly or taken to the Moody’s Single Sign On
                        Dashboard depending on how you access the application.
                      </p>
                    </v-card>
                    <h1 class="helpTitle" id="forget">Frequently Asked Questions</h1>
                    <v-card
                      class="mx-auto pa-4 paragraphText"
                      max-width="800"
                      color="grey lighten-5"
                      flat
                    >
                      <p class="question">
                        What should I do if I forget my username or password?
                      </p>
                      <p class="answer">
                        Click Reset Password on your application sign-in page, enter
                        the email address you listed in your user account settings,
                        and click Send Email. Look in your inbox for the system
                        generated email and follow the prompts.
                      </p>
                    </v-card>
                    <h1 class="helpTitle">How To's</h1>
                    <h2 class="helpSubTitle" id="sign-in-app">
                      Sign in to your Application
                    </h2>
                    <v-divider />
                    <v-card
                      class="howTo pa-4 paragraphText"
                      max-width="800"
                      color="grey lighten-5"
                      flat
                    >
                      <p>
                        1. Open a web browser and enter your
                        <b>application</b> web address. For example:
                      </p>
                      <a
                        href="http://pulse.moodysanalytics.com"
                        target="_blank"
                        class="link ml-4 mt-2"
                      >http://pulse.moodysanalytics.com</a>
                      <br />
                      <p class="mt-4">
                        2. Enter your email username and password, and then select
                        Sign In.
                      </p>
                    </v-card>
                    <h2 class="helpSubTitle" id="sign-in-dashboard">
                      Sign in to your Dashboard
                    </h2>
                    <v-divider />
                    <v-card
                      class="howTo pa-4 paragraphText"
                      max-width="800"
                      color="grey lighten-5"
                      flat
                    >
                      <p>
                        1. Open a web browser and enter your
                        <b>dashboard</b> web address. For example:
                      </p>
                      <a
                        href="http://accounts.moodysanalytics.com"
                        target="_blank"
                        class="link ml-4 mt-2"
                        >http://accounts.moodysanalytics.com</a
                      >
                      <br />
                      <p class="mt-4">
                        2. Enter your email username and password, and then select
                        Sign In.
                      </p>
                    </v-card>
                    <h2 class="helpSubTitle" id="report-security">
                      Report a Security Issue
                    </h2>
                    <v-divider />
                    <v-card
                      class="howTo pa-4 paragraphText"
                      max-width="800"
                      color="grey lighten-5"
                      flat
                    >
                      <p>
                        Click <a @click="handleClickHelpLink('security')"><b>here</b></a>
                        to report a security issue.
                      </p>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card flat id="side" class="mr-6">
                <v-row class="ma-2">
                  <v-col md="auto">
                    <h1 class="helpTitle">Contact Us</h1>
                    <p>For assistance, please contact Client Services:</p>
                    <p class="paragraphText mb-0 text-blue darken">Americas: {{contactUsNumbers.americas}}</p>
                    <p class="paragraphText mb-0 text-blue darken">EMEA: {{contactUsNumbers.emea}}</p>
                    <p class="paragraphText mb-0 text-blue darken">Asia Pacific: {{contactUsNumbers.asiaPacific}}</p>
                    <p class="paragraphText mb-0 text-blue darken">Japan: {{contactUsNumbers.japan}}</p>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <!-- END main: welcome, faq, how-tos -->

          <!-- ARTICLE: CONTACT US -->
          <v-dialog v-model="sendMessageModal" max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">
                  {{ helpTopicGroups[currentHelpTopicGroup]['header'] }}
                </span>
              </v-card-title>
              <v-divider />
              <v-card-text class="pt-4 py-0 my-0">
                <v-container>
                  <v-form ref="form" v-model="isFormValid">
                    <v-row>
                      <v-col cols="12" sm="8" class="ma-0 px-4 pa-0">
                        <v-text-field
                          v-model="senderName"
                          label="Name:"
                          required
                          :rules="validations.name"
                          :disabled="lockName"
                        />
                      </v-col>
                      <v-col cols="12" sm="8" class="ma-0 px-4 pa-0">
                        <v-text-field
                          v-model="senderEmail"
                          label="Email:"
                          required
                          :rules="validations.email"
                          :disabled="lockEmail"
                        />
                      </v-col>
                      <v-col cols="12" sm="8" class="ma-0 px-4 pa-0">
                        <v-text-field
                          v-model="senderPhone"
                          label="Phone Number:"
                          required
                          :rules="validations.phone"
                          :disabled="lockPhone"
                        />
                      </v-col>
                      <v-col cols="12" sm="8" class="ma-0 px-4 pa-0">
                        <v-text-field
                          v-model="senderCompany"
                          label="Company:"
                          required
                          :rules="validations.company"
                          :disabled="lockCompany"
                        />
                      </v-col>
                      <v-col cols="12" sm="8" class="ma-0 px-4 pa-0">
                        <v-autocomplete
                          :items="countryList"
                          v-model="senderCountry"
                          label="Country:"
                          required
                          :rules="validations.country"
                        />
                      </v-col>
                      <v-col cols="12" sm="10" class="ma-0 px-4 pa-0">
                        <v-autocomplete
                          :items="productOptions"
                          v-model="selectedProduct"
                          label="Which product is this in reference to?"
                          required
                          :rules="validations.product"
                        />
                      </v-col>
                      <v-col
                        v-if="!['access', 'security'].includes(this.currentHelpTopicGroup)"
                        class="ma-0 px-4 pa-0"
                        cols="12"
                        sm="10"
                      >
                        <v-autocomplete
                          :items="helpTopicList"
                          v-model="selectedHelpTopic"
                          label="What do you need help with?"
                          required
                          :rules="validations.selectedHelpTopic"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" class="ma-0 px-4 pa-0">
                        <v-textarea
                          v-model="senderMessage"
                          label="Message:"
                          autocomplete="Message"
                          required
                          rows="1"
                          auto-grow
                          :rules="validations.message"
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer />
                <v-btn 
                  color="blue darken-1" 
                  text 
                  @click="closeMessageModal()"
                >Cancel</v-btn>
                <vue-recaptcha
                  ref="recaptcha"
                  :sitekey="recaptchaSiteKey"
                  @verify="(token) => handleSubmitForm(token)"
                  @expired="onCaptchaExpired()"
                >
                  <v-btn
                    color="blue darken-1"
                    text
                    :disabled="!isFormValid || !recaptchaReady"
                  >Send Message</v-btn>
                </vue-recaptcha>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- END article: contact us -->
        </v-container>
      </v-row>
    </v-container>
  </div>
</template>


<script>
import axios from 'axios';
import VueRecaptcha from 'vue-recaptcha';
import contactUsNumbers from '../data/contact-us-numbers';
import countryList from '../data/country-list';
import validations from '../utils/field-validations';

/* eslint-disable no-console */
export default {
  name: "help",
  components: { VueRecaptcha },
  props: {
    appsList: Array,
    helpAppNames: Array,
    userInfo: Object,
  },
  data() {
    return {
      dashboardAppName: 'Single Sign On Dashboard',
      localUrl: window.location.origin,
      contactUsNumbers,
      countryList,
      validations,
      productOptions: [],
      helpTopicGroups: {
        'needHelp': {
          header: "Help Inquiry",
          topics: [
            "General inquiries",
            "Questions about my dashboard",
            "Cannot log in",
          ],
        },
        'access': {
          header: "Request Access",
          topics: [
            "Request access to an application"
          ],
        },
        'report': {
          header: "Send Feedback",
          topics: [
            "General feedback",
            "Report a bug",
            "Report a potential security issue",
          ],
        },
        'security': {
          header: "Report Security Issue",
          topics: [
            "Report a potential security issue"
          ],
        }
      },
      helpTopicList: [],
      lockName: false,
      lockEmail: false,
      lockPhone: false,
      lockCompany: false,

      currentHelpTopicGroup: 'needHelp',
      isFormValid: true,
      sendMessageModal: false,

      senderName: null,
      senderEmail: null,
      senderPhone: null,
      senderCompany: null,
      senderCountry: null,
      selectedProduct: null,
      selectedHelpTopic: null,
      senderMessage: null,
      recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      recaptchaReady: true,
    };
  },
  created() {
    this.setProductOptions();
    this.prefillForm();
    this.readUrlProps();
  },
  watch: {
    userInfo: "prefillForm",
    $route: "readUrlProps",
    helpAppNames: function() {
      this.setProductOptions();
      this.readUrlProps();
    },
    sendMessageModal: function (newDialog) {
      if (!newDialog) this.closeMessageModal();
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    setProductOptions() {
      this.productOptions = [
        this.dashboardAppName,
        ...this.helpAppNames,
      ];
    },
    async readUrlProps() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const actionType = urlParams.get('action');
      const selectedApp = urlParams.get('app');
      const salesForceMessage = urlParams.get('help-request');
      if (actionType === 'access') { 
        const userAuthenticated = await this.$auth.isAuthenticated();
        if (!userAuthenticated) { // if a user is requesting access they must be logged in
          return this.$router.replace('/login');
        }
        this.handleClickHelpLink('access');
        this.sendMessageModal = true;
        if (this.helpAppNames.includes(selectedApp)) {
          this.selectedProduct = selectedApp;
        }
      } else if (salesForceMessage === 'submitted') {
        this.$emit("displayAlert", "Thank you. Your message has been sent successfully.", false);
        this.$emit("displayAlert", "We will respond to you shortly", false);
      }
    },
    handleClickHelpLink(helpTopicGroup) {
      this.currentHelpTopicGroup = helpTopicGroup;
      this.helpTopicList = this.helpTopicGroups[helpTopicGroup].topics;
      if (helpTopicGroup === 'security' || helpTopicGroup === 'access') {
        this.selectedHelpTopic = this.helpTopicList[0];
      } else {
        this.selectedHelpTopic = null;
      }
      this.sendMessageModal = true;
    },
    async closeMessageModal() {
      this.sendMessageModal = false;
      await this.$refs.form.reset();
      this.prefillForm();
      if (this.$route.fullPath !== '/help') {
        this.$router.replace('help');
      }
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
      this.recaptchaReady = true;
    },
    async handleSubmitForm(token) {
      if (token) {
        this.recaptchaReady = false;
      }

      const sanitizeString = (input, regex) => {
        return regex !== undefined
          ? input.trim().replace(regex, "")
          : input.trim();
      };

      const senderParams = {
        recaptchaToken: token,
        senderName: sanitizeString(this.senderName, /[^A-Za-z0-9-&., ]/g),
        senderEmail: sanitizeString(this.senderEmail),
        senderPhone: sanitizeString(this.senderPhone),
        senderCompany: sanitizeString(this.senderCompany, /[^A-Za-z0-9-&., ]/g),
        senderCountry: this.senderCountry,
        selectedProduct: [
          {
            productName: this.dashboardAppName,
            salesforce: {
              sfProduct: 'SSO Dashboard',
              sfProductCategory: 'Credit Research',
              sfSubProduct: '',
            },
          },
          ...this.appsList
        ].find(app => app.productName === this.selectedProduct),
        selectedHelpTopic: this.selectedHelpTopic,
        senderMessage: sanitizeString(this.senderMessage, /[^A-Za-z0-9-_.,:?!"'/$&@ ]/g),
      };

      await this.sendMessageToEmailJs(senderParams);
    },
    async sendMessageToEmailJs(templateParams) {
      try {
        const payload = {
          ...templateParams,
          selectedProduct: {
            productName: templateParams.selectedProduct.productName,
            salesforce: templateParams.selectedProduct.salesforce,
          }
        }
        await axios.post(`${process.env.VUE_APP_SSO_API_URL}/help`, payload)
        this.closeMessageModal();
        this.$emit("displayAlert", "Thank you. Your message has been sent successfully.", false);
        this.$emit("displayAlert", "You Should receive a confirmation email shortly", false);
      } catch (error) {
        console.dir(error);
        if (error.response?.data?.message) {
          return this.$emit("displayAlert", error.response.data.message, true);
        }
        this.$emit("displayAlert", "Sorry, unable to send your message.", true);
      }
    },
    async prefillForm() {
      if (this.userInfo) {
        this.senderName = this.userInfo.firstName + ' ' + this.userInfo.lastName;
        this.senderEmail = this.userInfo.email;
        this.senderPhone = this.userInfo.mobilePhone;
        this.senderCompany = this.userInfo.organization;

        // These are in case the user hasn't updated their new account with profile details yet
        if (this.senderName) this.lockName = true;
        if (this.senderEmail) this.lockEmail = true;
        if (this.senderPhone) this.lockPhone = true;
        if (this.senderCompany) this.lockCompany = true;
      }
    }
  }
};
</script>

<style>
.theme--light.v-input--is-disabled input {
  color: rgb(0 56 105 / 41%);
}

.back-arrow {
  margin-right: 4px;
}
</style>
