<template>
  <div style="background: #fafafa">
    <v-container fluid grid-list-xl>
      <h1
        justify="center"
        align="center"
        class="categoryTitle mt-10 mb-6 ml-6"
      >
        You May Also Like
      </h1>
      <div v-swiper="swiperOptionsObject" instance-name="recommendations">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="app in chicklets"
            :key="app.productName"
          >
            <div>
              <v-card
                class="card pa-3"
                flat
                :key="app.sortOrder"
                background="#fafafa"
              >
                <v-btn
                  class="mx-3 pa-0"
                  :width="chickletWidth"
                  :height="chickletHeight"
                  background="#fafafa"
                  :color="checkIfSubscribed(app) ? '#09A1E0' : 'white'"
                  v-on:click="checkIfSubscribed(app)
                    ? buttonClick(app.loginUrl)
                    : createPopup(app)
                  "
                >
                  <v-list-item three-line>
                    <div id="bigAppContent">
                      <v-list-item-content class="ml-4">
                        <v-list-item-title
                          class="mt-4 ml-1 text--darken-3 bigCardTitle"
                          :class="checkIfSubscribed(app) ? 'white--text' : 'grey--text'"
                          v-html="formatName(app.productName)" />
                        <v-list-item-subtitle
                          id="bigCardSubtitle"
                          class="ml-2 text--darken-1"
                          :class="checkIfSubscribed(app) ? 'white--text' : 'grey--text'"
                          >{{ app.desc }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </div>
                    <v-img
                      width="204"
                      height="205"
                      class="imgBorderLarge"
                      :src="app.img + imageSize.medium"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                      </template>
                    </v-img>
                  </v-list-item>
                </v-btn>
              </v-card>
            </div>
          </div>
        </div>
        <div class="swiper-button-next" />
        <div class="swiper-button-prev" />
      </div>
    </v-container>
  </div>
</template>


<script>
import { directive } from "vue-awesome-swiper";
import { Swiper as SwiperClass, Navigation, Mousewheel } from "swiper/core";
//import "swiper-bundle.css";

SwiperClass.use([Navigation, Mousewheel]);

export default {
  directives: {
    swiper: directive,
  },
  props: {
    category: String,
    appsList: Array,
    subscribedList: Array,
    imageSize: Object,
  },
  data() {
    return {
      chickletWidth: "640",
      chickletHeight: "205",
      logoHeight: "33px",
      chicklets: [],
      swiperOptionsObject: {
        mousewheel: true,
        freeMode: true,
        slidesPerView: "auto",
        spaceBetween: -15,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  watch: {
    appsList: "getApps",
    category: "getApps",
  },
  created() {
    this.getApps();
  },
  methods: {
    getApps() {
      const allApps = this.appsList;
      let list = allApps.filter(
        (app) =>
          !app.tags.includes(this.category) && !this.checkIfSubscribed(app)
      );
      if (!list.length) {
        list = allApps.filter(
          (app) => !app.tags.includes(this.category) && app.trending
        );
      }
      list.sort(() => 0.5 - Math.random());
      list.splice(3);
      this.chicklets = [...list];
    },
    checkIfSubscribed(app) {
      return this.subscribedList.includes(app.oktaId);
    },
    createPopup(app) {
      this.$emit("cardClick", app);
    },
    buttonClick(href) {
      window.open(href, "_blank");
    },
    formatName(str) {
      return str.replace(/([a-z])([A-Z])/g, '$1<wbr>$2');
    },
  },
};
</script>


<style scoped>
.card {
  background: #fafafa;
}

::-webkit-scrollbar {
  display: none;
}
</style>
