// NOTE: Commented out code in this page is from the custom Okta widget

import Vue from 'vue';
import Router from 'vue-router';
import OktaVue from '@okta/okta-vue';
import { OktaAuth } from '@okta/okta-auth-js'

import oktaAuthConfig from '@/config';
import ImplicitCallback from '@/views/ImplicitCallback';
import Help from '@/components/Help';
import Home from '@/components/Home';
import Categories from '@/views/CategoryPage';
import Settings from '@/views/SettingsPage';
import Search from '@/views/SearchPage';
import Welcome from '@/components/Welcome';
import NotFound from '@/components/PageNotFound';

import "@/assets/fonts/fonts.css";
import "./index.css";

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      props: true,
      meta: { requiresAuth: true }
    },
    {
      path: '/callback',
      component: ImplicitCallback,
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      props: true,
      meta: { requiresAuth: true }
    },
    {
      path: '/categories/:category',
      name: 'categories',
      component: Categories,
      props: true,
      meta: { requiresAuth: true }
    },
    {
      path: '/help',
      name: 'help',
      component: Help,
      props: true,
    },
    {
      path: '/search/:keyword',
      name: 'search',
      component: Search,
      props: true,
      meta: { requiresAuth: true }
    },
    {
      path: '/welcome',
      name: 'welcome',
      component: Welcome,
      props: true,
    },
    {
      path: '/404',
      name: 'not-found',
      component: NotFound,
    },
    {
      path: '*',
      redirect: '/404',
    },
  ]
});

const oktaAuth = new OktaAuth({ ...oktaAuthConfig });
Vue.use(OktaVue, { 
  oktaAuth,
  // onAuthRequired: function () {
  //   oktaAuth.signInWithRedirect();
  // },
});

export default router;
