// NOTE: Commented out code in this page is from the custom Okta widget

export default {
  clientId: process.env.VUE_APP_OKTA_CLIENT_ID,
  issuer: `https://${process.env.VUE_APP_OKTA_DOMAIN}/oauth2/default`,
  // redirectUri: window.location.protocol + '//' + window.location.host + '/implicit/callback',
  redirectUri: window.location.protocol + '//' + window.location.host + '/callback',
  scopes: ['openid', 'profile', 'email', 'phone', 'groups'],
  pkce: true,
  responseType: "code",
  grantType: "authorization_code",
  display: "page",
  responseMode: "query",
  storageManager: {
    token: {
      storageTypes: ['sessionStorage', 'cookie']
    },
    cache: {
      storageTypes: ['sessionStorage', 'cookie']
    },
    transaction: {
      storageTypes: ['sessionStorage', 'cookie']
    }
  },
  tokenManager: {
    storage: 'sessionStorage',
    storageKey: 'sso-dash-okta-token-storage'
  },
  cookies: {
    secure: true
  }
}
