<!-- NOTE: Commented out code in this page is from the custom Okta widget -->

<template>
  <!-- <div style="marginTop: 200px" v-if="error">
    <v-main
      fluid
      color-white
      class="mt-12"
    >
      <v-row
        class="mt-12"
        justify="center"
        align="center"
      >
        <v-col md="auto" class="mt-12">
          <h1>
            {{ errorMessage }}
          </h1>
          <h3>
            Please contact our administrator in the
            <a href="/help">Help Page</a>
            to request access.
          </h3>
        </v-col>
      </v-row>
    </v-main>
  </div> -->
  <LoginCallback />
</template>


<script>
import { LoginCallback } from '@okta/okta-vue';

export default {
  name: "ImplicitCallback",
  components: {
    LoginCallback
  },
  // data: () => ({
  //   errorMessage: "",
  //   error: false,
  // }),
  // async beforeMount() {
  //   try {
  //     await this.$auth.handleLoginRedirect();
  //     this.$router.replace({
  //       path: this.$auth.getOriginalUri(),
  //     });
  //   } catch(err) {
  //     if (!err.message || err.message === 'Unable to parse a token from the url') {
  //       this.$router.replace('/login');
  //     } else {
  //       this.errorMessage = err.message;
  //       this.error = true;
  //     }
  //   }
  // },
};
</script>
