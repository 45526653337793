<template>
  <div>
    <v-container fluid grid-list-xl>
      <h1 class="sectionTitle">Your Subscribed Applications</h1>

      <div v-swiper="swiperOptionsObject" instance-name="subscribed">
        <div v-if="chicklets.length" class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="app in chicklets"
            :key="app.productName"
          >
            <div>
              <v-card
                class="card pa-3"
                flat
                :key="app.sortOrder"
              >
                <v-btn
                  class="mx-3 pa-0"
                  :width="chickletWidth"
                  :height="chickletHeight"
                  background="#fafafa"
                  color="#09A1E0"
                  dark
                  v-on:click="buttonClick(app.loginUrl)"
                >
                  <v-list-item three-line>
                    <v-img
                      width="95"
                      height="170"
                      class="mr-0 float-left imgBorderRight"
                      :src="app.img + imageSize.small"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row> 
                      </template>
                    </v-img>
                    <div id="appContent">
                      <v-list-item-content class="ml-4">
                        <v-list-item-title
                          style="margin-top: 15%"
                          class="medCardTitleRight ml-2"
                          v-html="formatName(app.productName)" />

                        <v-card-actions id="cardActionsRight">
                          <v-btn
                            id="cardActionsRight"
                            v-on:click="buttonClick(app.loginUrl)"
                            class="ml-2"
                            text
                            small
                            color="white"
                          >Open App &#8594;</v-btn>
                        </v-card-actions>
                      </v-list-item-content>
                    </div>
                  </v-list-item>
                </v-btn>
              </v-card>
            </div>
          </div>
        </div>

        <div v-else class="swiper-wrapper">
          <v-card
            class="card pa-3"
            flat
            :height="chickletHeight"
          />
        </div>

        <div class="swiper-button-next" />
        <div class="swiper-button-prev" />
      </div>
    </v-container>
  </div>
</template>


<script>
import { directive } from "vue-awesome-swiper";
import { Swiper as SwiperClass, Navigation, Mousewheel } from "swiper/core";
//import "swiper-bundle.css";

SwiperClass.use([Navigation, Mousewheel]);

export default {
  directives: {
    swiper: directive,
  },
  props: {
    appsList: Array,
    subscriptions: Array,
    imageSize: Object,
  },
  data() {
    return {
      chickletWidth: "292",
      chickletHeight: "170",
      logoHeight: "33px",
      chicklets: [],
      swiperOptionsObject: {
        mousewheel: true,
        freeMode: true,
        slidesPerView: "auto",
        spaceBetween: 15,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  watch: {
    subscriptions: "getApps"
  },
  created() {
    this.getApps()
  },  
  methods: {
    getApps() {
      this.chicklets = this.subscriptions
        .reduce((acc, product) => {
          const targetPrd = {...this.appsList.find(app => app.oktaId === product.appInstanceId)};
          if (targetPrd && targetPrd.oktaId) {
            targetPrd.sortOrder = product.sortOrder;
            acc.push(targetPrd);
          }
          return acc;
        }, [])
        .sort((a, b) => a.sortOrder - b.sortOrder);
    },
    buttonClick(href) {
      window.open(href, "_blank");
    },
    formatName(str) {
      return str.replace(/([a-z])([A-Z])/g, '$1<wbr>$2');
    },
  },
};
</script>
