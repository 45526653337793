<template>
  <div style="background: #fafafa">
    <v-btn id="backToLogin" text @click="$router.go(-1)" color>
      <v-icon x-small left dark>mdi-arrow-left</v-icon>Back
    </v-btn>

    <v-container fluid grid-list-xl>
      <h1 justify="center" class="categoryTitle mt-10">{{ categoryName }}</h1>
      <h1 justify="center" class="categorySubtitle mt-2 mb-3">Applications</h1>
      <div class="ml-4">
        <v-row justify="center">
          <v-card
            class="pa-3"
            flat
            v-for="app in chicklets"
            :key="app.productName"
          >
            <v-hover v-slot="{ hover }" open-delay="100">
              <v-btn
                class="mx-3 pa-0"
                :width="chickletWidth"
                :height="chickletHeight"
                background="#fafafa"
                :color="checkIfSubscribed(app) ? '#09A1E0' : 'white'"
                v-on:click="checkIfSubscribed(app)
                  ? buttonClick(app.loginUrl)
                  : createPopup(app)
                "
              >
                <v-list-item three-line>
                  <div
                    id="appContent"
                    :class="[
                      checkIfSubscribed(app) ? '' : 'unsubscribed',
                    ]"
                  >
                    <v-list-item-content
                      v-if="checkIfSubscribed(app)"
                      class="ml-2"
                    >
                      <v-list-item-title
                        class="white--text medCardTitle ml-2"
                        v-html="formatName(app.productName)" />
                      <div>
                        <v-btn
                          @click.native.stop.prevent
                          v-for="tag in app.tags"
                          :key="tag"
                          x-small
                          class="mb-2 ml-2"
                          height="20"
                          outlined
                          color="white"
                          :to="{ path: '/categories/' + tag.trim().toLowerCase() }"
                          >{{tag.trim()}}</v-btn>
                      </div>

                      <v-card-actions id="cardActions">
                        <v-btn
                          id="cardActions"
                          v-on:click="buttonClick(app.loginUrl)"
                          class="ml-2"
                          text
                          small
                          color="white"
                        >Open App &#8594;</v-btn>
                      </v-card-actions>
                    </v-list-item-content>

                    <v-list-item-content
                      v-if="!checkIfSubscribed(app)"
                      class="ml-2"
                    >
                      <v-list-item-title
                        v-if="!hover"
                        class='#09A1E0--text medCardTitle'
                        v-html="formatName(app.productName)" />
                      <div v-if="!hover">
                        <v-btn
                          @click.native.stop.prevent
                          v-for="tag in app.tags"
                          :key="tag"
                          height="18"
                          :to="{ path: '/categories/' + tag.trim().toLowerCase() }"
                          class="categoryTag"
                          outlined
                          color="blue"
                          >{{tag.trim()}}</v-btn>
                      </div>
                      <v-list-item-subtitle
                        v-if="hover"
                        class="#09A1E0--text"
                        id="hoverDesc"
                        :title="app.desc"
                        >{{ app.desc }}</v-list-item-subtitle>
                      <v-card-actions id="cardActions">
                        <v-btn
                          v-if="!hover"
                          id="cardActions"
                          v-on:click="createPopup(app)"
                          class="ml-2"
                          text
                          small
                          color="#09A1E0"
                        >More Info &#8594;</v-btn>
                      </v-card-actions>
                    </v-list-item-content>
                  </div>
                  <v-img
                    width="95"
                    height="170"
                    class="mr-0 float-right imgBorder"
                    :src="app.img + imageSize.small"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        />
                      </v-row>
                    </template>
                  </v-img>
                </v-list-item>
              </v-btn>
            </v-hover>
          </v-card>
        </v-row>
      </div>
      <Recommendations 
        :category="categoryName"
        :appsList="appsList"
        :subscribedList="subscribedList"
        :imageSize="imageSize"
        v-on:cardClick="createPopup"
      />
      <Footer />
    </v-container>
  </div>
</template>


<script>
import Recommendations from "@/components/Recommendations";
import Footer from "@/components/Footer";

export default {
  components: {
    Recommendations,
    Footer
  },
  props: {
    category: String,
    appsList: Array,
    subscribedList: Array,
    imageSize: Object,
  },
  data() {
    return {
      categoryName: this.getCategoryName(this.$route),
      chickletWidth: "292",
      chickletHeight: "170",
      logoHeight: "33px",
      chicklets: [],
      recommend: [],
    };
  },
  watch: {
    appsList: "getApps",
    '$route': function(route) {
      this.categoryName = this.getCategoryName(route);
      this.getApps();
    },
  },
  created() {
    this.getApps();
  },
  methods: {
    getCategoryName(route) {
      return route.params.category
        .replace(/%20/g, " ")
        .replace(
          /\w\S*/g,
          txt => {
            if (txt.toUpperCase() === txt) {
              return txt;
            }
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }
        );
    },
    getApps() {
      const appsWithCategory = this.appsList.filter(app => {
        for (let tag of app.tags) {
          if (tag.toLowerCase() === this.categoryName.toLowerCase()) {
            return true;
          }
        }
      })
      if (appsWithCategory.length) {
        this.chicklets = [...appsWithCategory];
      } else if (this.appsList.length){
        alert('Sorry, no products match. Redirecting you back to the previous page.');
        this.$router.go(-1);
      }
    },
    checkIfSubscribed(app) {
      return this.subscribedList.includes(app.oktaId);
    },
    createPopup(app) {
      this.$emit("cardClick", app);
    },
    buttonClick(href) {
      window.open(href, "_blank");
    },
    formatName(str) {
      return str.replace(/([a-z])([A-Z])/g, '$1<wbr>$2');
    },
  },
};
</script>


<style scoped>
.categorySubtitle {
  font-size: 1.125rem;
  text-align: center;
}
</style>
